import { ReactNode } from 'react';

import { Logo } from './logo';

export function GuestLayout({ children }: { children: ReactNode }) {
  return (
    <div className="min-h-screen grid grid-cols-3 gap-4 py-16 px-8 bg-white">
      <div className="col-span-2 h-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <h1 className="mb-4 text-5xl font-bold">Project Lens</h1>
          <div className="mt-6 mb-2">Powered by</div>
          <div>
            <Logo />
          </div>
        </div>
      </div>
      <div className="border-l"> {children}</div>
    </div>
  );
}
